import React from 'react'
import { AbsoluteFill, Img, Sequence } from 'remotion';
import 'animate.css/animate.min.css';
import { Animation } from 'remotion-animation';

const LogoLayer = ({ src, layer, multiplyNum }) => {
    return (
        <Sequence from={Number(layer.animation.delay.toString().replace("s", "")) * 30 + 30}>
            <AbsoluteFill>
                <Animation style={{ position: "absolute", height: `${layer.position.height}%`, width: `${layer.position.width}%`, left: `${layer.position.left}%`, top: `${layer.position.top}%` }} duration={Number(layer.animation.duration.toString().replace("s", ""))} animateName={layer.animation.name.replace("animate__", "")} >
                    < Img
                        style={{ height: "100%", width: "100%", borderRadius: layer.style.borderRadius }}
                        src={src}
                        onError={(event) => {
                            // Handle image loading error here
                            console.log("error")
                        }}
                    />
                </Animation>
            </AbsoluteFill >
        </Sequence>
    )
}

export default LogoLayer
