import React, { useState, useEffect } from 'react';

import { fetchData } from '../../../../../Redux/Actions/CommonActions';
import { onWriteDoc } from '../../../../../Redux/Actions/ProjectActions';
import { useDispatch } from 'react-redux';
import WriteTableRows from './WriteTableRows';

const WriteDoc = ({ cId, gotoAsset, setGotoAsset }) => {
    const dispatch = useDispatch()
    const [writeData, setWriteData] = useState([])
    const [loader, setLoader] = useState({
        fetch: false,
        create: false
    })
    const [data, setData] = useState({
        title: "",
        description: ""
    })

    const handleChange = (e) => {
        const { name, value } = e.target
        setData({
            ...data,
            [name]: value
        })
    }

    const handleSubmit = (e) => {
        e.preventDefault()
        setLoader({
            ...loader,
            create: true
        })
        let obj = {
            cId: cId,
            ...data
        }
        dispatch(onWriteDoc(obj, fetchDoc, setData, loader, setLoader))
    }

    const fetchDoc = () => {
        setLoader({
            ...loader,
            fetch: true
        })
        let data = {
            cId: cId
        }
        dispatch(fetchData("fetch-write-all-data", data, setWriteData, loader, setLoader))
    }

    useEffect(() => {
        if (writeData.length > 0) {
            const isData = writeData.find(({ description }) => description !== "")
            if (isData) {
                setGotoAsset({
                    ...gotoAsset,
                    write: true
                })
            } else {
                setGotoAsset({
                    ...gotoAsset,
                    write: false
                })
            }
        }
    }, [writeData])

    useEffect(() => {
        fetchDoc()
    }, [])

    return (
        <>
            <form onSubmit={handleSubmit}>
                <div className='common-input-wrap mt-0'>
                    <label htmlFor="">Document Title</label>
                    <input
                        className='common-input'
                        type="text"
                        placeholder='Title'
                        name='title'
                        value={data.title}
                        onChange={handleChange}
                        required
                    />
                </div>
                <div className='common-input-wrap'>
                    <label htmlFor="">Content</label>
                    <textarea
                        className='common-input'
                        value={data.description}
                        placeholder='Write Content Here'
                        onChange={handleChange}
                        name="description"
                        required
                    />
                </div>
                <div className='common-input-wrap'>
                    <button className='site-link' type='submit' disabled={loader.create}><span>{loader.create ? <>Creating <i className="fa fa-spinner fa-spin" /></> : "Create"}</span></button>
                </div>
            </form>


            <h2 className='pt-5'>Stored Documents</h2>
            <div className='doc-table '>
                <div className='table-responsive'>
                    <table className='table'>
                        <tr>
                            <th>Name</th>
                            <th>Created On</th>
                            <th></th>
                        </tr>
                        {writeData.length > 0 ?
                            writeData.map((curElem, index) => {
                                return (
                                    <React.Fragment key={index}>
                                        <WriteTableRows
                                            curElem={curElem}
                                            fetchDoc={fetchDoc}
                                        />
                                    </React.Fragment>
                                )
                            })
                            :
                            <tr>
                                <td colSpan={3} className='text-center'>
                                    {loader.fetch ? <i className="fa fa-spinner fa-spin" style={{ fontSize: 25, color: "#3f429b" }} /> :
                                        "You have not written a document yet!"
                                    }
                                </td>
                            </tr>
                        }
                    </table>
                </div>
            </div>

        </>
    )
}

export default WriteDoc