import React, { useEffect, useState } from 'react'
import { AbsoluteFill, Video } from 'remotion';

const Intro = ({ src, duration, playerRef }) => {

    const [loader, setLoader] = useState(false)

    useEffect(() => {
        setLoader(true)
    }, [src]);

    return (
        <AbsoluteFill>
            {
                loader ?
                    <div
                        className="loader-center-temp1"
                        style={
                            {
                                background: 'linear-gradient( rgba(0, 0, 0, 0.7) 50%, rgba(0, 0, 0, 0.7)50%)',
                                width: "100%",
                                height: "100%",
                                position: 'absolute',
                                zIndex: "2"
                            }}
                    >
                        <i className="fa fa-spinner fa-spin mr-2" style={{ fontSize: "50px" }} />
                    </div> : ''
            }
            < Video
                height="100%"
                width="100%"
                src={src}
                volume={1}
                // playbackRate={2} //speed
                muted={false}
                loop={false}
                onError={(event) => {
                    // Handle image loading error here
                    console.log("error")
                }}
                onLoadedData={
                    () => {
                        if (playerRef.current) {
                            setLoader(false)
                            playerRef.current.play()
                        }
                    }
                }
            />
        </AbsoluteFill>
    )
}

export default Intro
