import React, { useEffect, useState } from 'react';
import { BsPlayFill, } from 'react-icons/bs'
import { FaSpinner } from 'react-icons/fa'
import { useSelector } from 'react-redux'
import { Modal } from 'react-bootstrap';
import { AiOutlineClose } from 'react-icons/ai'
import { useDispatch } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import { FiChevronRight } from "react-icons/fi"
import { BsBox2, BsCollectionPlay } from "react-icons/bs"
import { onCountCampaign, onCreateCampaign } from '../../../Redux/Actions/ProjectActions';
import { appName } from '../../../Global/Global';
import { GrProjects } from "react-icons/gr";
import { RiProjectorLine } from "react-icons/ri";

const HomeBanner = () => {
    const dispatch = useDispatch()
    const navigate = useNavigate()
    const auth = useSelector(state => state.auth)
    const rebrand = useSelector(state => state.rebrand)

    const [loader, setLoader] = useState(false);
    const [show, setShow] = useState(false);
    const [showPopup, setShowPopup] = useState(false);
    const [showClose, setShowClose] = useState(false)
    const [count, setCount] = useState([])
    const handleClose = () => setShow(false);
    const handleShow = () => setShow(true);

    const handelClose2 = () => {
        setShowPopup(false);
    }
    const colorObj = {
        color: "#ffffff",
        position: "absolute",
        top: '27px',
        right: "-6px",
        fontSize: 11,
        background: "#515151",
        width: 150,
        textAlign: "center",
        padding: "8px 7px",
        borderRadius: 2,
        cursor: "pointer"
    }

    const handleMsgClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    const handelPopupClick = () => {
        document.cookie = `popupClicked${auth.user.id}=true; expires=Fri, 31 Dec 9999 23:59:59 GMT`;
        setShowPopup(false);
    }

    useEffect(() => {
        const popupCookie = document.cookie
        if (popupCookie.includes(`popupClicked${auth.user.id}=true`)) {
            setShowPopup(false)
        } else {
            setShowPopup(true)
        }
    }, []);
    const countData = () => {
        dispatch(onCountCampaign(setCount))
    }

    const createCampaign = () => {
        if (!loader) {
            setLoader(true)
            dispatch(onCreateCampaign({}, navigate, setLoader))
        }
    }
    useEffect(() => {
        countData()
    }, [])
    return (
        <>
            <section className='hero-banner'>
                <div className='hero-banner-video'>
                    <iframe src="https://player.vimeo.com/video/902950722?background=1&amp;autoplay=1&amp;loop=1&amp;byline=0&amp;title=0" width="640" height="360" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" allowfullscreen title="app intro video"></iframe>
                </div>
                <div className='container'>
                    <div className='hero-banner-in'>
                        <div className='hero-banner-txt'>
                            <h6>Welcome to</h6>
                            <h1>{rebrand.data ? rebrand.data.name : <> <span>AI</span> Sellers</>}</h1>
                            <p>Step into the future of digital marketing where we take your marketing campaigns to the next level. Effortlessly create compelling promotional materials, from videos and articles to eBooks and landing pages with the power of AI. Tailor content to perfection and experience marketing like never before with {rebrand.data ? rebrand.data.name : appName}.
                            </p>
                            <a className='site-link mt-4' onClick={handleShow}> <span><BsPlayFill /> Watch Video</span> </a>
                        </div>
                        <div className="hero-banner-right">
                            {+auth.user.isClientAccount !== 1 ?
                                <Link onClick={createCampaign}>
                                    <div className="dashboard-block credits">
                                        <div className="credits-item">
                                            <div className="credits-item-left">
                                                <div className="credits-icon">{loader ? <FaSpinner className="spin" /> : <BsBox2 />} </div>
                                                <div className="credits-details">
                                                    <h2>New Project</h2>
                                                </div>
                                            </div>
                                            <div className="next-icon"><FiChevronRight /></div>
                                        </div>
                                    </div>
                                </Link> : null}
                            <Link to={'/projects'}>
                                <div className="dashboard-block credits">
                                    <div className="credits-item">
                                        <div className="credits-item-left">
                                            <div className="credits-icon"><BsCollectionPlay /></div>
                                            <div className="credits-details">
                                                <h2>View all Projects</h2>
                                            </div>
                                        </div>
                                        <div className="next-icon"><FiChevronRight /></div>
                                    </div>
                                </div>
                            </Link>
                            <Link>
                                <div className="dashboard-block credits">
                                    <div className="credits-item">
                                        <div className="credits-item-left">
                                            <div className="credits-icon alt"><RiProjectorLine style={{ fontSize: "27px" }} /></div>
                                            <div className="credits-details">
                                                <h2>{count} Projects</h2>
                                            </div>
                                        </div>
                                        <div className="next-icon"><FiChevronRight /></div>
                                    </div>
                                </div>
                            </Link>
                        </div>
                    </div>
                </div>
            </section>
            {/* <CreatePrModal
                show={show2}
                handleClose={handleClose2}
            /> */}

            <Modal className="theme-modal ai_img_wrap video-modal ds" show={showPopup} onHide={handelClose2} centered>
                <Modal.Body>
                    <div onClick={() => setShowClose(true)} className="vidClose" >
                        <div style={{ position: "relative" }}>
                            <div className='modalClose' onClick={handleClose}><AiOutlineClose style={{
                                fontSize: "30px", marginBottom: "3px", top: '50px',
                                right: '-8px', position: "absolute"
                            }} /></div>
                            {showClose ?
                                <p style={colorObj} onClick={handleMsgClick}>Don't show me again</p> : ""}

                        </div>

                    </div>
                    <div className="modalVid">
                        {/* <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick}>
                                            <img src={BannerImg} alt="bannerImg" title="AiStaff-Fb-Group" />
                                        </a> */}
                        <div className="para_ai h-100">
                            <a href="https://aIclubhub.com/" target="_blank" rel="nooper noreferrer" onClick={handelPopupClick} style={{ display: "block" }} className="w-100 d-flex h-100 justify-content-center align-items-center flex-column">
                                <p style={{
                                    fontSize: "55px",
                                    fontWeight: "bold",
                                    textAlign: "center",
                                    lineHeight: "1.3",
                                    color: "#fff"
                                }}>Click Here To Join  Members <br /> Only Facebook Group </p>
                                <button className="btn btn-danger mt-3 ps-4 pe-4 pt-2 pb-2" style={{ display: " block", fontSize: "45px" }}> Click Here</button>
                            </a>
                        </div>
                    </div>

                </Modal.Body>
            </Modal>

            <Modal className='theme-modal video-modal xl' show={show} onHide={handleClose} centered>
                <Modal.Body>
                    <div className='modalClose' onClick={handleClose}><AiOutlineClose /></div>
                    <div className="ratio ratio-16x9">
                        <iframe class="embed-responsive-item" src="https://player.vimeo.com/video/903678974?badge=0&amp;autopause=0&amp;player_id=0&amp;app_id=58479" width="1920" height="1074" frameborder="0" allow="autoplay; fullscreen; picture-in-picture" title="AiSocialsWalkthrough"></iframe>
                    </div>
                </Modal.Body>
            </Modal>

        </>
    )
}

export default HomeBanner