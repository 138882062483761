export const colorObj = [
    {
        hex: '#ffffffff',
        rgb: {
            r: 255,
            g: 255,
            b: 255,
            a: 1
        }
    },
    {
        hex: '#e1e1e1ff',
        rgb: {
            r: 225,
            g: 225,
            b: 225,
            a: 1
        }
    },
    {
        hex: '#d7d7d7ff',
        rgb: {
            r: 215,
            g: 215,
            b: 215,
            a: 1
        }
    },
    {
        hex: '#ccccccff',
        rgb: {
            r: 204,
            g: 204,
            b: 204,
            a: 1
        }
    },
    {
        hex: '#c2c2c2ff',
        rgb: {
            r: 194,
            g: 194,
            b: 194,
            a: 1
        }
    },
    {
        hex: '#b7b7b7ff',
        rgb: {
            r: 183,
            g: 183,
            b: 183,
            a: 1
        }
    },
    {
        hex: '#a1a1a1ff',
        rgb: {
            r: 161,
            g: 161,
            b: 161,
            a: 1
        }
    },
    {
        hex: '#959595ff',
        rgb: {
            r: 149,
            g: 149,
            b: 149,
            a: 1
        }
    },
    {
        hex: '#e8c9ffff',
        rgb: {
            r: 232,
            g: 201,
            b: 255,
            a: 1
        }
    },
    {
        hex: '#c87effff',
        rgb: {
            r: 200,
            g: 126,
            b: 255,
            a: 1
        }
    },
    {
        hex: '#f34affff',
        rgb: {
            r: 243,
            g: 74,
            b: 255,
            a: 1
        }
    },
    {
        hex: '#d19df8ff',
        rgb: {
            r: 209,
            g: 157,
            b: 248,
            a: 1
        }
    },
    {
        hex: '#9b33e9ff',
        rgb: {
            r: 155,
            g: 51,
            b: 233,
            a: 1
        }
    },
    {
        hex: '#8133dbff',
        rgb: {
            r: 129,
            g: 51,
            b: 219,
            a: 1
        }
    },
    {
        hex: '#7033d5ff',
        rgb: {
            r: 112,
            g: 51,
            b: 213,
            a: 1
        }
    },
    {
        hex: '#23803fff',
        rgb: {
            r: 35,
            g: 128,
            b: 63,
            a: 1
        }
    },
    {
        hex: '#54c423ff',
        rgb: {
            r: 84,
            g: 196,
            b: 35,
            a: 1
        }
    },
    {
        hex: '#606888ff',
        rgb: {
            r: 96,
            g: 104,
            b: 136,
            a: 1
        }
    },
    {
        hex: '#7c83acff',
        rgb: {
            r: 124,
            g: 131,
            b: 172,
            a: 1
        }
    },
    {
        hex: '#5b69beff',
        rgb: {
            r: 91,
            g: 105,
            b: 190,
            a: 1
        }
    },
    {
        hex: '#30409bff',
        rgb: {
            r: 48,
            g: 64,
            b: 155,
            a: 1
        }
    },
    {
        hex: '#223cdbff',
        rgb: {
            r: 34,
            g: 60,
            b: 219,
            a: 1
        }
    },
    {
        hex: '#dc143cff',
        rgb: {
            r: 220,
            g: 20,
            b: 60,
            a: 1
        }
    },
    {
        hex: '#ff7f50ff',
        rgb: {
            r: 225,
            g: 127,
            b: 80,
            a: 1
        }
    }
]